// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-index-js": () => import("/opt/build/repo/src/Templates/LandingPage/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-about-us-page-index-js": () => import("/opt/build/repo/src/Templates/AboutUsPage/index.js" /* webpackChunkName: "component---src-templates-about-us-page-index-js" */),
  "component---src-templates-blog-list-page-index-js": () => import("/opt/build/repo/src/Templates/BlogListPage/index.js" /* webpackChunkName: "component---src-templates-blog-list-page-index-js" */),
  "component---src-templates-news-list-page-index-js": () => import("/opt/build/repo/src/Templates/NewsListPage/index.js" /* webpackChunkName: "component---src-templates-news-list-page-index-js" */),
  "component---src-templates-reservation-page-index-js": () => import("/opt/build/repo/src/Templates/ReservationPage/index.js" /* webpackChunkName: "component---src-templates-reservation-page-index-js" */),
  "component---src-templates-medical-team-page-index-js": () => import("/opt/build/repo/src/Templates/MedicalTeamPage/index.js" /* webpackChunkName: "component---src-templates-medical-team-page-index-js" */),
  "component---src-templates-environment-page-index-js": () => import("/opt/build/repo/src/Templates/EnvironmentPage/index.js" /* webpackChunkName: "component---src-templates-environment-page-index-js" */),
  "component---src-templates-cuisine-page-index-js": () => import("/opt/build/repo/src/Templates/CuisinePage/index.js" /* webpackChunkName: "component---src-templates-cuisine-page-index-js" */),
  "component---src-templates-article-detail-page-index-js": () => import("/opt/build/repo/src/Templates/ArticleDetailPage/index.js" /* webpackChunkName: "component---src-templates-article-detail-page-index-js" */),
  "component---src-templates-product-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ProductListingPage/index.js" /* webpackChunkName: "component---src-templates-product-listing-page-index-js" */),
  "component---src-templates-product-detail-page-index-js": () => import("/opt/build/repo/src/Templates/ProductDetailPage/index.js" /* webpackChunkName: "component---src-templates-product-detail-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("/opt/build/repo/src/Templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-order-detail-page-index-js": () => import("/opt/build/repo/src/Templates/OrderDetailPage/index.js" /* webpackChunkName: "component---src-templates-order-detail-page-index-js" */),
  "component---src-templates-profile-page-index-js": () => import("/opt/build/repo/src/Templates/ProfilePage/index.js" /* webpackChunkName: "component---src-templates-profile-page-index-js" */),
  "component---src-templates-cart-page-index-js": () => import("/opt/build/repo/src/Templates/CartPage/index.js" /* webpackChunkName: "component---src-templates-cart-page-index-js" */),
  "component---src-templates-checkout-page-index-js": () => import("/opt/build/repo/src/Templates/CheckoutPage/index.js" /* webpackChunkName: "component---src-templates-checkout-page-index-js" */),
  "component---src-templates-select-cvs-page-index-js": () => import("/opt/build/repo/src/Templates/SelectCvsPage/index.js" /* webpackChunkName: "component---src-templates-select-cvs-page-index-js" */),
  "component---src-templates-social-login-result-page-index-js": () => import("/opt/build/repo/src/Templates/SocialLoginResultPage/index.js" /* webpackChunkName: "component---src-templates-social-login-result-page-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

